<template>
    <b-modal :title="$t('messages.hotSalePromotion')" content-class="card card-ecart form-rounded-inputs"
        header-class="card-header p-3 " v-model="modalHotsale" cancel-variant="danger"
        size="lg" centered hide-header-close>
        <div class="row">
            <div class="col-12 ecart-banner mb-4">
                <router-link to="/dashboard/discounts?hot_sale=true">
                    <img src="@/assets/images/banner/hot-sales/es/promo-1.png" loading="lazy" alt="" class=" border-0 "
                        v-if="$i18n.locale == 'es'">
                    <img src="@/assets/images/banner/hot-sales/en/promo-1.png" loading="lazy" alt="" class=" border-0 "
                        v-else>
                </router-link>
                <div class="mt-4" v-html="$t('messages.hotSaleMessage')">
                </div>
            </div>
            <div class="col-12">
                <p class="text-center">
                    <router-link to="/dashboard/discounts?hot_sale=true"
                        class=" btn btn-lg btn-success rounded-pill f-w-700 my-4" v-text="$t('messages.startNow')">
                    </router-link>
                </p>
            </div>
            <div class="col-auto mx-auto">
                <div class="form-group ">
                    <div class="form-check text-center">
                        <input class="form-check-input" type="checkbox" id="dontShowAgain" v-model="dontShowAgain">
                        <label class="form-check-label" for="dontShowAgain" v-text="$t('messages.dontShowAgain')">
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <p class="text-center"> 
                    <small> 
                        <router-link to="/dashboard/promotions?tab=2" v-text=" '*' + $t('messages.promoTerms')">
                        </router-link>
                    </small>
                </p>
            </div>
        </div>
        <template v-slot:modal-footer>
            <div class="d-flex justify-content-end w-100">
                <div class="ml-auto">
                    <b-button variant="none" class=" mr-2" @click=" modalHotsale = !modalHotsale"
                        v-text="$t('general.button.close')">
                    </b-button>
                </div>
            </div>
        </template>
    </b-modal>

</template>

<script>
    export default {
        data() {
            return {
                modalHotsale: false,
                showCustom: false,
                dontShowAgain: null,
                discount: {
                    discount: null,
                    terms: null,
                    storeId: null,
                },
                storeList: [],
                percentages: [{
                        value: 20,
                        label: '20%'
                    },
                    {
                        value: 30,
                        label: '30%'
                    },
                    {
                        value: 40,
                        label: '40%'
                    },
                ],
            }
        },
        watch: {
            dontShowAgain() {
                if (this.dontShowAgain) {
                    localStorage.setItem('HotSaleModal', true);
                } else {
                    localStorage.removeItem('HotSaleModal');
                }
            }
        },
        methods: {
            fnCheckLocal() {
                let today = this.$moment();
                let diffDays = today.diff( this.$moment('27-05-2021', this.CONST.DATE_FORMATS.DD_MM_YYYY) ,'days' );
                if ((localStorage.getItem('HotSaleModal') == null || localStorage.getItem('HotSaleModal') == undefined) && (diffDays <= 0) ) {
                    this.modalHotsale = true;
                }
            },
        },
        mounted() {
            this.fnCheckLocal();
        }
    }
</script>

<style lang="scss" scoped>
    .ecart-banner {
        img {
            width: 100%;
            // height: 100px;
            object-fit: contain;
            background-position: center;
        }
    }
</style>