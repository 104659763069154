<template>
    <div class="card" id="topSales">
        <div  :class=" showContent ? 'card-header' : 'card-body' ">
            <div class="row justify-content-between align-items-center">
                <div class="col-8">
                    <p class="f-w-600" v-text="$t('dashboard.dashboard.titles.totalSales')"> </p>
                    <p class="card-title">
                        <small v-text="$t('dashboard.dashboard.messages.totalSales')"></small>
                    </p>
                </div>
                <div class="col-auto">
                    <button class="btn text-success rounded-pill p-0" @click="showContent = !showContent; fnGlobalScroll( showContent ? 'topSales' :'','center')">
                        <i class=" fa fa-chevron-circle-down fa-lg"></i>
                    </button>
                </div>
            </div>

        </div>
        <div class="card-body animated fadeIn" v-show="showContent">
            <div class="card bg-light m-0 " v-show="topsList.totalSales.length ">
                <div class="card-body ">
                    <div>
                        <div class="d-flex" v-for=" (sale,index) in topsList.totalSales " :key="index">
                            <p class="m-0" v-text="sale.id"> </p>
                            <div class="linedoted w-100 mt-auto"></div>
                            <p class="m-0" v-text="fnFormatPrice( sale.total, '' )"> </p>
                        </div>
                    </div>
                </div>
            </div>
            <no-data sm :dataMessage="$t('noDataMessages.noInformaton')" v-show=" topsList.totalSales.length == 0"
                :isLoading="loading.data" :showBtn="false">
            </no-data>
        </div>
        <div class="card-footer animated fadeIn" v-show="showContent">
            <div class="row">
                <div class="col">
                    <p class=" m-0 ">
                        <span v-text="$t('dashboard.dashboard.titles.totalOrders') + ': ' "> </span>
                        <span v-text=" totalOrders "> </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState,
    } from 'vuex';
    export default {
        data() {
            return {
                showContent: false,
            }
        },

        computed: {
            ...mapState('dashboard', ['topsList', 'loading']),
            totalOrders() {
                let tempTotal = 0;
                if (this.topsList.totalOrders.length > 0) {
                    this.topsList.totalOrders.map(currency => {
                        tempTotal = tempTotal + currency.orders;
                    })
                    return tempTotal;
                } else {
                    return 0;
                }
            }
        },
    }
</script>

<style>

</style>