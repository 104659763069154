<template>
    <div class="card" id="productsSold">
        <div  :class=" showContent ? 'card-header' : 'card-body' ">
            <div class="row justify-content-between align-items-center">
                <div class="col-8">
                    <p class="f-w-600" v-text="$t('dashboard.dashboard.titles.productSold')"> </p>
                    <p class="card-title">
                        <small v-text="$t('dashboard.dashboard.messages.productsSold')"></small>
                    </p>
                </div>
                <div class="col-auto">
                    <button class="btn text-success rounded-pill p-0" @click="showContent = !showContent; fnGlobalScroll(showContent ? 'productsSold' : '','center')">
                        <i class=" fa fa-chevron-circle-down fa-lg"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body text-center animated fadeIn" v-show="showContent">
            <h4 class=" text-info " v-text=" topsList.totalProductSold "> </h4>
        </div>
        <div class="card-footer animated fadeIn" v-show="showContent">
            <div class="row">
                <div class="col">
                    <p class=" m-0 ">
                        <span v-text="$t('dashboard.dashboard.titles.totalCountries') + ': ' ">
                        </span>
                        <span v-text="topsList.totalCountries.length "> </span> </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState,
    } from 'vuex';
    export default {
        data() {
            return {
                showContent: false,
            }
        },
        computed: {
            ...mapState('dashboard', ['topsList', 'loading']),
        }

    }
</script>

<style>

</style>