<template>
  <div class="card" id="topCountries">
    <div :class=" showContent ? 'card-header' : 'card-body' ">

      <div class="row justify-content-between align-items-center">
        <div class="col-8">
          <p class="f-w-600" v-text="$t('dashboard.dashboard.titles.countries')"> </p>

          <p class="card-title">
            <small v-text="$t('dashboard.dashboard.messages.countries')"></small>
          </p>
        </div>
        <div class="col-auto">
          <button class="btn text-success rounded-pill p-0" @click="showContent = !showContent ; fnGlobalScroll( showContent ? 'topCountries' : '','center')">
            <i class=" fa fa-chevron-circle-down fa-lg"></i>
          </button>
        </div>
      </div>


    </div>

    <div class="card-body animated fadeIn" v-show="showContent">
      <div class=" row text-uppercase " v-for=" ( shipping , index ) in topsList.productShippingCountries "
        :key=" index ">
        <div class="col">
          <span class=" pr-1 mr-1 mr-2 " v-text=" index + 1 +'.-' "> </span>
          <i class="flag-icon mr-2" :class=" 'flag-icon-'+ shipping.id.toLowerCase() "></i>
          <strong v-text=" shipping.id "> </strong>
        </div>
        <div class="col-auto ml-auto ">
          <span v-text=" shipping.orders "> </span>
        </div>
      </div>
      <no-data sm :dataMessage="$t('noDataMessages.noInformaton')"
        v-show=" topsList.productShippingCountries.length == 0" :isLoading="loading.data" :showBtn="false">
      </no-data>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  export default {
    data() {
      return ({
        showContent: false,
      })
    },
    computed: {
      ...mapState('dashboard', ['topsList', 'loading']),
    }
  }
</script>

<style>

</style>