<template>
  <div class="card card-ecart ">
    <div class="card-header">
      <h5 v-text="$t('dashboard.dashboard.titles.tips')"> </h5>
      <p class="m-0"> <small v-text="$t('dashboard.dashboard.messages.tips')"></small> </p>
    </div>
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <tbody>
          <tr v-for=" (product,index) in productsTips " :key=" index ">
            <td>
              <div class="row">
                <div class="col-12">
                  <div class="row align-items-center ">
                    <div class="col-2 col-sm-1 col-md-1 col-lg-1 ">
                      <img loading="lazy" v-image="product.imageUrl" class="  img-cover-30 border ">
                    </div>
                    <div class="col-7 col-sm-9 col-md-9 col-lg-9">
                      <p class="m-0 ">
                        <strong>

                        <router-link :to="'/dashboard/products/' + product.id"
                          v-text="product.name? product.name : product.id ">
                        </router-link>
                        </strong>
                      </p>
                      <p v-show="product.message" class="animated " :class=" showMessage ? 'fadeInUp': 'fadeOut' ">
                        <small>
                          <i class="fa fa-exclamation-circle "></i>
                          <span v-if="product.message">
                            {{  $t('dashboard.dashboard.productDetails.' + product.message )  }} </span>
                        </small>
                      </p>
                    </div>
                    <div class="col-3 col-sm-2 col-md-2 col-lg-2">
                      <p class="text-right" v-text="product.completed + '%' "></p>
                    </div>
                  </div>
                </div>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
      <no-data :dataMessage="$t('noDataMessages.noInformaton')" sm v-show=" productsTips.length == 0"
        :isLoading="loading.data" :showBtn="false">
      </no-data>

    </div>
  </div>
</template>


<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  export default {
    data() {
      return ({
        showMessage: false,
        tipsInterval: null,
      });
    },
    computed: {
      ...mapState('dashboard', ['productsTips', 'loading']),
    },
    watch: {
      productsTips() {

        if (this.productsTips.length > 0) {
          this.fnMessages();
          this.tipsInterval = setInterval(() => {
            this.fnMessages();
          }, 4000);
        }
      }
    },
    methods: {
      fnMessages() {
        this.showMessage = false;
        setTimeout(() => {
          this.productsTips.map(product => {
            if (product.index < (product.details.length)) {
              product.message = product.details[product.index];
              product.index = product.index + 1;
            } else {
              product.message = product.details[0]
              product.index = 1;
            }
          })
        }, 1000);
        setTimeout(() => {
          this.showMessage = true;
        }, 1000);
      }
    },
    beforeDestroy() {
      if (this.tipsInterval != null ) {
        clearInterval(this.tipsInterval);
      }
    }
  }
</script>

<style>

</style>