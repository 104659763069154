<template>
  <div class="card"  >
    <div :class=" showContent ? 'card-header' : 'card-body' ">
      <div class="row justify-content-between align-items-center">
        <div class="col-8">
          <p class="f-w-600" v-text="$t('dashboard.dashboard.titles.topSales')"> </p>
          <p class="card-title"> <small v-text="$t('dashboard.dashboard.messages.topSales')"></small> </p>
        </div>
        <div class="col-auto">
          <button class="btn text-success rounded-pill p-0" @click="showContent = !showContent; fnGlobalScroll(showContent ? 'topSales' : '','start')">
            <i class=" fa fa-chevron-circle-down fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body animated fadeIn" v-show="showContent" id="topSales">
      <div class=" row " v-for=" ( product , index ) in topSales " :key=" index ">
        <div class="col-10">
          <p class=" text-truncate ">
            <span class=" pr-1 mr-1 " v-text=" index + 1 + '.-' "> </span>
            <span v-text=" product.name "> </span>
          </p>
        </div>
        <div class="col-2 ml-auto my-auto ">
          <p class="text-right">
            <span v-text=" product.sales "> </span>
          </p>
        </div>
      </div>
      <no-data sm :dataMessage="$t('noDataMessages.noInformaton')" v-show=" topSales.length == 0"
        :isLoading="loading.topSales" :showBtn="false">
      </no-data>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  export default {
    data() {
      return ({
        showContent: false,
      });
    },
    computed: {
      ...mapState('dashboard', ['topSales', 'loading']),
    }
  }
</script>