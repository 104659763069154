<template>
  <div class="card" id="storesList">
    <div :class=" showContent ? 'card-header' : 'card-body' ">

      <div class="row justify-content-between align-items-center">
        <div class="col-8">
          <p class="f-w-600">
            <span v-text="$t('dashboard.dashboard.titles.stores')"></span>
            <small v-show=" topsList.stores.total != null ">
              <strong v-text=" ' (' +topsList.stores.total+ ')' "> </strong>
            </small>
             </p> 
          <p class="card-title">
            <small v-text="$t('dashboard.dashboard.messages.stores')"></small>
          </p>
        </div>
        <div class="col-auto">
          <button class="btn text-success rounded-pill p-0" @click="showContent = !showContent; fnGlobalScroll( showContent ? 'storesList' : '','start')">
            <i class=" fa fa-chevron-circle-down fa-lg"></i>
          </button>
        </div>
      </div>


    </div>
    <div class="card-body p-0 animated fadeIn" v-show="showContent">
      <div v-show="topsList.stores.total != null ">
        <div class="row justify-content-center">
          <div class="col-6 p-3">
            <p class="text-center ">
              <span class="f-16 f-w-500 " v-text="topsList.stores.stores_active"></span>
              <br>
              <span>Online</span>
            </p>
          </div>
          <div class="col-6 border-left p-3">
            <p class="text-center ">
              <span class="f-16 f-w-500 " v-text="topsList.stores.stores_inactive"></span>
              <br>
              <span>Offline</span>
            </p>
          </div>
        </div>
      </div>

      <no-data sm :dataMessage="$t('noDataMessages.noInformaton')" v-show=" topsList.stores.total == null "
        :isLoading="loading.data" :showBtn="false" class="py-4">
      </no-data>
    </div>
    <div class="card-footer py-3 animated fadeIn" v-show="showContent" v-for=" (storeInfo,index) in storesStats "
      :key="index">
      <div class="row align-items-center">
        <div class="col-7 col-md-6 mb-3 ">
          <p class=" text-truncate ">
            <i class="fa fa-sm fa-store mr-2 text-success "></i>
            <span class="f-w-500 " v-text="storeInfo.name"></span>
          </p>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-auto mx-auto">
                  <i class="fa fa-lg fa-comments"></i>
                  <i class="fa fa-sm fa-check-circle mt-2 text-success expretion animated fadeIn "
                    v-show="storeInfo.answer_time == 'good' "></i>
                  <i class="fa fa-sm fa-exclamation-circle mt-2 text-warning expretion animated fadeIn "
                    v-show="storeInfo.answer_time == 'regular' "></i>
                  <i class="fa fa-sm fa-exclamation-circle mt-2 text-danger expretion animated fadeIn "
                    v-show="storeInfo.answer_time == 'bad' "></i>
                </div>
              </div>
              <p class="text-center">
                <small v-text=" $t('messages.storeStats.questions.' + storeInfo.answer_time ) "></small>
              </p>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-auto mx-auto">
                  <i class="fa fa-lg fa-truck"></i>
                  <i class="fa fa-sm fa-check-circle mt-2 text-success expretion animated fadeIn "
                    v-show="storeInfo.ship_time == 'good' "></i>
                  <i class="fa fa-sm fa-exclamation-circle mt-2 text-warning expretion animated fadeIn "
                    v-show="storeInfo.ship_time == 'regular' "></i>
                  <i class="fa fa-sm fa-exclamation-circle mt-2 text-danger expretion animated fadeIn "
                    v-show="storeInfo.ship_time == 'bad' "></i>
                </div>
              </div>
              <p class="text-center">
                <small v-text=" $t('messages.storeStats.shipment.' + storeInfo.ship_time ) "></small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  import {
    mapState,
  } from 'vuex';
  export default {
    data() {
      return ({
        showContent: false,
      });
    },
    computed: {
      ...mapState('dashboard', ['topsList', 'loading', 'storesStats']),
    },
    mounted() {}
  }
</script>

<style scoped>
  .gradient-store-name {
    background: rgb(129, 178, 48);
    background: linear-gradient(90deg, rgba(129, 178, 48, 1) 5%, rgba(255, 255, 255, 1) 30%);

  }
</style>