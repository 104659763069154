<template>
  <div class=" h-100-vh ">
    <a class="banner-ecart d-flex justify-content-center card "
      href="https://parapaquetes.com/collections/sobres-de-polietileno/products/sobres-de-polietileno-ecart-para-paqueteria?utm_source=seller%20ecart&utm_medium=banner%20seller&utm_campaign=para%20paquetes"
      target="_blank">
      <img src="@/assets/images/banner/3.png" loading="lazy" alt="" class="banner-image card m-0 border-0 d-sm-none ">
      <img src="@/assets/images/banner/2.png" loading="lazy" alt=""
        class="banner-image card m-0 border-0 d-none d-sm-block d-lg-none ">
      <img src="@/assets/images/banner/1.png" loading="lazy" alt=""
        class="banner-image card m-0 border-0 d-none d-lg-block ">
    </a>
    <div class="row d-md-none my-4 ">
      <router-link class="col-3 d-flex flex-column justify-content-center align-items-center text-center "
        to="/dashboard/stores">
        <img src="@/assets/images/icons/dash/stores.svg" loading="lazy" class="w-75 mb-2 ">
        <p v-text="$t('general.stores')" class="f-w-600">
        </p>
      </router-link>
      <router-link class="col-3 d-flex flex-column justify-content-center align-items-center text-center "
        to="/dashboard/products">
        <img src="@/assets/images/icons/dash/products.svg" loading="lazy" class="w-75 mb-2 ">
        <p v-text="$t('general.products')" class="f-w-600">
        </p>
      </router-link>
      <router-link class="col-3 d-flex flex-column justify-content-center align-items-center text-center "
        to="/dashboard/orders">
        <img src="@/assets/images/icons/dash/orders.svg" loading="lazy" class="w-75 mb-2 ">
        <p v-text="$t('general.orders')" class="f-w-600">
        </p>
      </router-link>
      <router-link class="col-3 d-flex flex-column justify-content-center align-items-center text-center "
        to="/dashboard/shipping">
        <img src="@/assets/images/icons/dash/shipping.svg" loading="lazy" class="w-75 mb-2 ">
        <p v-text="$t('dashboard.menu.shipping')" class="f-w-600">
        </p>
      </router-link>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8">

        <div class="row form-rounded-inputs">
          <div class="col-6 col-md-4">
            <div class="form-group animated fadeIn ">
              <label for="" v-text="$t('general.view')"></label>
              <div class="input-group">
                <select class="custom-select" v-model="selectGraph ">
                  <option value="chart" v-text="$t('tables.sales')"></option>
                  <option value="products" v-text="$t('dashboard.dashboard.titles.tips')"></option>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-success">
                    <i class="fa fa-store"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">

          </div>
        </div>
        <SalesChart class="animated fadeIn" v-show="selectGraph == 'chart' " />
        <ProductTips class="animated fadeIn" v-show="selectGraph == 'products' " />
      </div>

      <div class="col-12 col-lg-4 ">
        <StoresInformation />
        <TotalSales />
        <ProductsSold />
        <TopShipments />
        <TopSales />
        <TopViews />
      </div>
    </div>

    <DashboardHotsale />

  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';

  import SalesChart from './components/dashboard-sales-chart';
  import StoresInformation from './components/dashboard-stores';
  import TopSales from './components/dashboard-top-sales';
  import TopViews from './components/dashboard-top-views';
  import TopShipments from './components/dashboard-top-shipments';
  import ProductTips from './components/dashboard-product-tips';
  import TotalSales from './components/dashboard-total-sales';
  import ProductsSold from './components/dashboard-products-sold';
  import DashboardHotsale from './components/dashboard-hotsale';

  export default {
    name: "Dash",
    components: {
      StoresInformation,
      SalesChart,
      TopSales,
      TopViews,
      TopShipments,
      ProductTips,
      TotalSales,
      ProductsSold,
      DashboardHotsale,
    },
    data() {
      return {
        selectGraph: 'chart'
      };
    },
    computed: {
      ...mapState('dashboard', ['topsList', 'loading', 'countOrderPending']),
      totalOrders() {
        let tempTotal = 0;
        if (this.topsList.totalOrders.length > 0) {
          this.topsList.totalOrders.map(currency => {
            tempTotal = tempTotal + currency.orders;
          })
          return tempTotal;
        } else {
          return 0;
        }
      }
    },
    methods: {
      ...mapActions('dashboard', ['fnApiGetData']),
    },
    mounted() {
      this.fnApiGetData();
    },
  }
</script>

<style lang="scss" scoped>
  .banner-ecart {
    background-color: #0b473c;
    // overflow: hidden;
  }

  .banner-image {
    background-color: #0b473c;
    object-fit: contain;
    min-height: 80px;
    max-height: 100px;
    width: 100%;
  }

  .cards-container {
    height: 60vh;
    overflow-y: scroll;
  }
</style>